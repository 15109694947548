import React from 'react';
import { startOfMonth, endOfMonth, eachDayOfInterval, format } from 'date-fns';
import './Calendar.css';

function Calendar({ images, position, monthImages }) {
    const today = new Date();
    const start = startOfMonth(today);
    const end = endOfMonth(today);

    const days = eachDayOfInterval({ start, end });

    const yearStr = format(today, 'yyyy'); // 年を取得
    const monthStr = format(today, 'MM');  // 月を取得

    const yearDigits = yearStr.split('');
    const monthDigits = monthStr.split('');

    const currentMonth = today.getMonth() + 1; // 現在の月 (1-12)
    const currentMonthImage = monthImages[currentMonth];

    return (
        <div>
            {currentMonthImage && (
                <div className="month-image">
                    <img src={currentMonthImage} alt={`Month ${currentMonth}`} width={100} />
                </div>
            )}
            <h2>
                {/* 年の表示 */}
                {yearDigits.map((digit, index) =>
                    images[digit] ? (
                        <img key={`year-${index}`} src={images[digit]} alt={digit} width={20} />
                    ) : (
                        <span key={`year-${index}`}>{digit}</span>
                    )
                )}
                年
                {/* 月の表示 */}
                {monthDigits.map((digit, index) =>
                    images[digit] ? (
                        <img key={`month-${index}`} src={images[digit]} alt={digit} width={20} />
                    ) : (
                        <span key={`month-${index}`}>{digit}</span>
                    )
                )}
                月
            </h2>
            <div className="calendar">
                {days.map((day) => {
                    const dateNumber = format(day, 'd'); // 日付を数字として取得（先頭にゼロなし）
                    const digits = dateNumber.split('');

                    // アップロードされた画像に日付が含まれているかチェック
                    if (images[dateNumber]) {
                        // 日付全体に対応する画像がある場合、その画像を表示
                        return (
                            <div key={day} className="calendar-cell">
                                <img src={images[dateNumber]} alt={dateNumber} className="full-date-image" />
                            </div>
                        );
                    } else {
                        // 各桁の数字画像を組み合わせて表示
                        return (
                            <div key={day} className="calendar-cell">
                                {digits.map((digit, index) =>
                                    images[digit] ? (
                                        <img key={index} src={images[digit]} alt={digit} width={20} />
                                    ) : (
                                        <span key={index}>{digit}</span>
                                    )
                                )}
                            </div>
                        );
                    }
                })}
            </div>
        </div>
    );
}

export default Calendar;
