import React, { useState, useEffect } from 'react';
import './App.css';
import Calendar from './components/Calendar/Calendar';
import ImageUploader from './components/ImageUploader';
import MonthGrid from './components/MonthGrid';
import Header from './components/Header/Header';
import HowTo from './components/HowTo/HowTo';
import MainVisual from './components/MainVisual/MainVisual';
import About from './components/About/About';
import OptionSelect from './components/OptionSelect';
import FAQ from './components/FAQ/FAQ';
import Footer from './components/Footer/Footer';
import Billing from './components/Billing';

function App() {
  const [images, setImages] = useState({});
  const [additionalImages, setAdditionalImages] = useState({});
  const [recognizedDigits, setRecognizedDigits] = useState([]);
  const [positions, setPositions] = useState({});
  const [monthImages, setMonthImages] = useState({});
  const [startMonth, setStartMonth] = useState(1);
  const [startDay, setStartDay] = useState(0);
  const [language, setLanguage] = useState('日本語');
  const [selectedFrame, setSelectedFrame] = useState(0);
  const [totalPrice, setTotalPrice] = useState(550);


  useEffect(() => {
    const framePrices = OptionSelect.frames.map(frame => frame.price); // OptionSelectからフレームの価格を取得
    setTotalPrice(550 + framePrices[selectedFrame]);
  }, [selectedFrame]);

  // 認識結果を受け取るコールバック関数
  const handleRecognitionComplete = (result) => {
    setRecognizedDigits(result.digits);
    setImages(result.images || {});
  };

  return (
    <div className="container">
      <Header />
      <MainVisual />
      <About />
      <HowTo />

      <div className="wrapper build">

        <h3 className="h3 main-f-color">カレンダーをつくろう</h3>

        {/* build① */}
        <MonthGrid monthImages={monthImages} setMonthImages={setMonthImages} />

        {/* build② */}
        <ImageUploader onRecognitionComplete={handleRecognitionComplete} />

        {/* complete iamge */}
        <div className="finish_image">
          <h3 className="h3 main-f-color">完成イメージ</h3>

            {/* カレンダー */}
            <Calendar
              images={{ ...images, ...additionalImages }}
              positions={positions}
              monthImages={monthImages}  // 追加
            />

        </div>

        {/* build③ */}
        <OptionSelect
          startMonth={startMonth}
          setStartMonth={setStartMonth}
          startDay={startDay}
          setStartDay={setStartDay}
          language={language}
          setLanguage={setLanguage}
          selectedFrame={selectedFrame}
          setSelectedFrame={setSelectedFrame}
        />
      </div>

      <Billing totalPrice={totalPrice} />

          {/* TODO: ダウンロード */}

      <FAQ />
      <Footer />
    </div>
  );
}

export default App;
