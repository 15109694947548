import React from 'react';
import './FAQ.css'; // スタイルシートをインポート

function FAQ() {
  return (
    <section className="wrapper back faq">
      <h3 className="h3">よくある質問</h3>
      <div className="faq_list">
        <details open className="faq_item">
          <summary className="h4 question">PDFを取得するにはどうしたらいいですか？</summary>
          <span className="answer">決済完了後、カレンダーのPDFが添付されたメールが届きます。</span>
        </details>

        <details className="faq_item">
          <summary className="h4 question">PDFをコンビニで印刷したい場合はどうすればいいですか？</summary>
          <span className="answer">ファミリーマート、ポプラグループ、ミニストップ、ローソンでネットワークプリントをする場合は<a href="https://networkprint.ne.jp/sharp_netprint/ja/top.aspx" target="_blank" className="otherlink">こちら</a>、セブンイレブンでネットワークプリントする場合は<a href="https://www.printing.ne.jp/" target="_blank" className="otherlink">こちら</a>からご確認ください。<br />コンビニにデータを持参する場合は各種コンビニのコピー機についてのページをご確認ください。</span>
        </details>

        <details className="faq_item">
          <summary className="h4 question">不具合や要望等がある場合はどうすればいいですか？</summary>
          <span className="answer"><a href="" className="otherlink">「お問い合わせ」フォーム</a>からお気軽にお問い合わせください。</span>
        </details>
      </div>
    </section>
  );
}

export default FAQ;
