import React from 'react';
import frame1 from '../assets/frame1.jpg';
import './Build.css'; // スタイルシートをインポート

function OptionSelect({ startMonth, setStartMonth, startDay, setStartDay, language, setLanguage, selectedFrame, setSelectedFrame }) {
  const months = [
    '1月', '2月', '3月', '4月', '5月', '6月',
    '7月', '8月', '9月', '10月', '11月', '12月'
  ];
  const days = ['日', '月'];
  const languages = ['英語', '日本語'];
  const frames = [
    { thumbnail: frame1, name: 'スタンダード', price: 0 },
    { thumbnail: frame1, name: 'くるま', price: 300 },
    { thumbnail: frame1, name: 'でんしゃ', price: 300 },
    { thumbnail: frame1, name: 'むし', price: 300 },
    { thumbnail: frame1, name: 'ようふく', price: 0 },
    { thumbnail: frame1, name: 'くまたん', price: 300 },
    { thumbnail: frame1, name: 'おすなば', price: 300 },
    { thumbnail: frame1, name: 'おふろ', price: 300 },
    { thumbnail: frame1, name: 'おふとん', price: 0 },
    { thumbnail: frame1, name: 'えほん', price: 300 },
    { thumbnail: frame1, name: 'くれよん', price: 300 },
    { thumbnail: frame1, name: 'フレームを依頼', price: 1500 },
  ];

  OptionSelect.frames = frames; // フレームの価格情報をエクスポート

  return (
    <div className="option-select">
      <h4 className="h4">③ オプション選択（任意）</h4>
      <div className="option_item">
        <label className="option_label">始まり月<span>（デフォルトは1月始まり）</span></label>
        <select value={startMonth} onChange={(e) => setStartMonth(e.target.value)} className="option_select">
          {months.map((month, index) => (
            <option key={index} value={index + 1}>{month}</option>
          ))}
        </select>
      </div>
      <div className="option_item">
        <label className="option_label">始まる曜日<span>（デフォルトは日曜始まり）</span></label>
        <select value={startDay} onChange={(e) => setStartDay(e.target.value)} className="option_select">
          {days.map((day, index) => (
            <option key={index} value={index}>{day}</option>
          ))}
        </select>
      </div>
      <div className="option_item">
        <label className="option_label">曜日の言語<span>（デフォルトは英語）</span></label>
        <select value={language} onChange={(e) => setLanguage(e.target.value)} className="option_select">
          {languages.map((lang, index) => (
            <option key={index} value={lang}>{lang}</option>
          ))}
        </select>
      </div>
      <div>
        <label className="option_label">フレーム選択<span>（デフォルトはスタンダード）</span></label>
        <div className="frame-grid">
          {frames.map((frame, index) => (
            <div key={index} className="frame-option">
              <img
                src={frame.thumbnail}
                alt={`frame-${index}`}
                className={`frame-thumbnail ${selectedFrame === index ? 'selected' : ''}`}
                onClick={() => setSelectedFrame(index)}
              />
              <div className="frame-info">
                <p className="frame_name">{frame.name}</p>
                <p className="frame_price">（追加料金：{frame.price}円）</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OptionSelect;
