import React from 'react';
import flow01 from '../../assets/flow01.png';
// import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';
import './HowTo.css'; // スタイルシートをインポート

function HowTo() {
  return (
    <div className="wrapper how2">
      <h3 className="h3">作成のながれ</h3>

      <Splide
        hasTrack={false}
        aria-label="作成のながれ"
        options={{
          arrowPath: 'm15.5 0.932-4.3 4.38...'
        }}
      >
        <div className="custom-wrapper">
          <SplideTrack>
            <SplideSlide>
              <img src={flow01} alt="1月から12月までの写真をえらぶ" className="img how2img" />
              <p className="how2txt">① 1月から12月までの<br />写真をえらぶ</p>
            </SplideSlide>
            <SplideSlide>
              <img src={flow01} alt="0から9までの手書き写真をアップロードする" className="img how2img" />
              <p className="how2txt">② 0から9までの手書き写真を<br />アップロードする</p>
            </SplideSlide>
            <SplideSlide>
              <img src={flow01} alt="オプション選択" className="img how2img" />
              <p className="how2txt">③ オプション選択</p>
            </SplideSlide>
          </SplideTrack>
          <div class="splide__arrows"></div>
        </div>
      </Splide>

    </div>
  );
}

export default HowTo;
