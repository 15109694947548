import React from 'react';
import './Footer.css'; // スタイルシートをインポート
import logo from '../../assets/logo.png';

function Footer() {
  const isBrowser = typeof window !== 'undefined';

  const copyUrl = async (url) => {
    if (!isBrowser) return;

    try {
      await navigator.clipboard.writeText(url);
      console.log('URLのコピーに成功しました');
    } catch {
      console.error('URLのコピーに失敗しました');
    }
  }

  return (
    <footer className="footer">
      <img src={logo} alt="logo" className="logo footer_logo" />
      <ul className="flex share_list">
        <li className="share_item">
          <a href="https://twiter.com/share?url={URL}" target="_blank" className="share_link">
            <span className="shareicon">X</span><br />
            <span className="sharename">X</span>
          </a>
        </li>
        <li className="share_item">
          <a href="http://www.facebook.com/share.php?u={URL}" target="_blank" className="share_link">
            <span className="shareicon">F</span><br />
            <span className="sharename">Facebook</span>
          </a>
        </li>
        {/* <li className="share_item">
          <a href="" target="_blank" className="share_link">
            <span className="shareicon">T</span><br />
            <span className="sharename">Tiktok</span>
          </a>
        </li> */}
        <li className="share_item">
          <a href="http://line.me/R/msg/text/?{URL}%0a{手書きカレンダーころみ}" target="_blank" className="share_link">
            <span className="shareicon">L</span><br />
            <span className="sharename">LINE</span>
          </a>
        </li>
        <li className="share_item">
          <button
            onClick={() => copyUrl(window.location.href)}
            className="share_link"
          >
            <span className="shareicon">C</span><br />
            <span className="sharename">URLcopy</span>
          </button>
        </li>
      </ul>
      <p>運営：SEEDa</p>
      <p className="copyright">COPYRIGHT©coromi ALL RIGHTS RESERVED.</p>
    </footer>
  );
}

export default Footer;
